.groupBtn1:hover .groupArr1{
    padding-left:10px;
   transition:0.3s;
}
.groupBtn1{
    width:170px;
   
    
}
.lineH{
line-height:60px;
}
.groupBtn1:hover .groupLine1{
    width:25px;
}
.groupLine1{
width:15px;
height:2px;
margin-top:0px;
}

@media only screen and (max-width:600px) {
    .lineH{
line-height:45px;
}

}